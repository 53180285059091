/* You can add global styles to this file, and also import other style files */

@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
@import "~@ng-select/ng-select/themes/default.theme.css";

@font-face {
  font-family: "Be Vietnam";
  src: url('assets/fonts/Be_Vietnam_Pro/BeVietnamPro-Thin.ttf');
  font-weight: 100;
}

@font-face {
  font-family: "Be Vietnam";
  src: url('assets/fonts/Be_Vietnam_Pro/BeVietnamPro-ExtraLight.ttf');
  font-weight: 200;
}

@font-face {
  font-family: "Be Vietnam";
  src: url('assets/fonts/Be_Vietnam_Pro/BeVietnamPro-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: "Be Vietnam";
  src: url('assets/fonts/Be_Vietnam_Pro/BeVietnamPro-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: "Be Vietnam";
  src: url('assets/fonts/Be_Vietnam_Pro/BeVietnamPro-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: "Be Vietnam";
  src: url('assets/fonts/Be_Vietnam_Pro/BeVietnamPro-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: "Be Vietnam";
  src: url('assets/fonts/Be_Vietnam_Pro/BeVietnamPro-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: "Be Vietnam";
  src: url('assets/fonts/Be_Vietnam_Pro/BeVietnamPro-ExtraBold.ttf');
  font-weight: 800;
}

@font-face {
  font-family: "Be Vietnam";
  src: url('assets/fonts/Be_Vietnam_Pro/BeVietnamPro-Black.ttf');
  font-weight: 900;
}

.primary-color {
  color: #0000EE;
}

.primary-color-70 {
  color: rgba(0, 0, 238, 0.7);
}

.primary-bg-70 {
  background-color: rgba(0, 0, 238, 0.7);
}

a {
  text-decoration: none !important;
}

.form-input {
  background-color: rgba(186, 186, 248, 0.1) !important;
  border-radius: 5px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: rgba(0, 0, 0, 0.6) !important;
  padding: 1rem !important;
}

.dark-color {
  color: rgba(0, 0, 0, 0.6);
}


.account-btn {
  background-color: rgb(0, 0, 238, 0.8) !important;
}

.account-btn:hover {
  background-color: rgba(0, 0, 238, 0.74) !important;
}

.skip-btn {
  background-color: #D9D9D9;
}

.skip-btn:hover {
  background-color: #eaeaea;
}

a {
  cursor: pointer !important;
}

.njangi-icon {
  background-color: rgba(25, 118, 210, 0.1);
}

.target-icon {
  background-color: rgba(200, 16, 46, 0.1);
}

.njangi-color {
  color: #1976D2;
}

.target-color {
  color: #C8102E;
}

.bars {
  border-radius: 100%;
  padding: 1rem 0.5rem;
  color: #C8102E;
  background: #F8F8FC;
  border: 3px solid rgba(200, 16, 46, 0.2);
}

* {
  font-family: "Be Vietnam", serif;
}

.pagination ::ng-deep .ngx-pagination .current {
  background: rgba(0, 0, 238, 0.7);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*html, body { height: 100%; }*/
/*body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }*/

.help-info {
  font-size: .8rem !important;
  color: rgba(0, 0, 0, .5) !important;
}

.help-info:hover {
  color: #000000 !important;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

body {
  background: rgba(157, 157, 247, 0.1);
}

.monkap-logo-image {
  background-color: #0000EE;
  padding: 0.5rem;
  border-radius: 100%;
  width: 2.5rem;
  height: 2.5rem;
}

.monkap-logo {
  font-size: 1.5rem;
  font-weight: normal;
}

.mat-tab-label-active .logo-black {
  filter: invert(10%) sepia(100%) saturate(7151%) hue-rotate(247deg) brightness(80%) contrast(145%);
}

.eye-b {
  position: absolute;
  top: 9px;
  right: 10px;
}

.password-div {
  position: relative;
}

.mo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Adjust the opacity as needed */
  z-index: 9999;
}

.ngx-toastr {
  width: 500px !important;
}
